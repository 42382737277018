/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useRef, useState } from 'react';
import { ROUTES } from '../../api/routes';
import SideMenuIcon from '../../assets/icons/side-menu.svg?react';
import searchIcon from '../../assets/icons/search.svg';
import closeIcon from '../../assets/icons/close.svg';
import LoveIcon from '../../assets/icons/love.svg';
import InfoIcon from '../../assets/icons/info.svg?react';
import OrascomLogo from '../../assets/images/logo-horizontal.png';
import OrascomWhiteLogo from '../../assets/images/logo-horizontal-white.png';
import SearchBar from '../common/search-bar/search-bar';
import { Link, useLocation } from 'react-router-dom';
import { NavyButton } from '../common/button/button';
import Modal from 'libs/common-components/src/lib/modal/modal';
import ContactUsForm from '../common/contact-us-form/contact-us-form';
import { useTranslation } from 'react-i18next';
import SideNavBar from '../side-nav-bar/side-nav-bar';
import {
  LanguageDropdown,
  SelectDropdown,
  Tooltip,
} from '@orascom/common-components';
import styles from './header.module.scss';

import { CurrencyContext } from '../../contexts/currency.context';
import {
  analyticsHeaderSearchClickEvent,
  analyticsHeaderTalkToSalesClickEvent,
  analyticsHeaderWishListClickEvent,
  analyticsSideBarCustomEvent,
} from '../../utils/analytics-events';
import { CURRENCIES } from '../../utils/exchange.rates.utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { CommonEventParameters } from '@orascom/utils';
export interface HeaderProps {
  header_transparent: boolean;
  header_home: boolean;
  auth?: boolean;
}

export function SecondaryHeader() {
  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: 'EGP',
      value: 0,
    }
  );

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  return (
    <div className={`${styles['wrapper-secondary']} container`}>
      <Link to={ROUTES.Home.path}>
        <img src={OrascomLogo} alt="logo" className={styles['orascom-logo']} />
      </Link>

      {/* {reservationContext?.reservationState ===
        ReservationStateEnum.INITIAL_STATE && (
        <div className={styles['dropdowns']}>
          {process.env.NX_SHOW_LANGUAGES_OPTIONS ? <LanguageDropdown /> : null}
          <SelectDropdown
            options={[
              { label: 'EGP', value: 1 },
              { label: 'USD', value: 2 },
              { label: 'EUR', value: 3 },
              { label: 'OMR', value: 4 },
            ]}
            placeholder={selectedCurrency.label}
            defaultValue={selectedCurrency}
            onChange={(val) => {
              if (val) {
                setActiveCurrency(val.label);
                setSelectedCurrency(val);
              }
            }}
            isSearchable={false}
          />
        </div>
      )} */}
    </div>
  );
}

export function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation();
  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);
  const [showCurrencyTooltip, setShowCurrencyTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [isSubMenuSidebar, setIsSubMenuSidebar] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { setActiveCurrency, disableCurrencySetting } =
    useContext(CurrencyContext);

  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: 'EGP',
      value: 0,
    }
  );

  useEffect(() => {
    if (disableCurrencySetting.disabled) {
      setSelectedCurrency({
        label: 'USD',
        value: 1,
      });
    }
  }, [disableCurrencySetting.disabled]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const searchBarRef = useRef<HTMLDivElement>(null);
  const handleShowSearchModal = () => {
    if (location.pathname === ROUTES.AvailableUnits.path) {
      return;
    }
    setShowSearchModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowContactModal(false);
    setShowSearchModal(false);
    document.body.style.overflow = '';
  };

  const openSidebar = (isSidebarState: boolean) => {
    return setIsSidebar(isSidebarState);
  };

  const toggleSubMenuSideBarState = (isSubMenuSideBarState: boolean) => {
    return setIsSubMenuSidebar(isSubMenuSideBarState);
  };

  const preventBodyOverflow = () => {
    if (isSidebar || showSearchModal || showContactModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (
        isOpen &&
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, [searchBarRef, isOpen]);

  useEffect(() => {
    setIsOpen(false);
    setShowSearchModal(false);
  }, [location.pathname]);

  useEffect(() => {
    preventBodyOverflow();
  }, [isSidebar, showSearchModal, showContactModal]);

  const currencyOptions = CURRENCIES.map((cur, ind) => ({
    label: cur,
    value: ind,
  }));

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };
  const handleShowContactModal = () => {
    setShowContactModal(true);
    setIsSidebar(false);
    analyticsHeaderTalkToSalesClickEvent(analyticsEventParams);
    document.body.style.overflow = 'hidden';
  };
  return (
    <header
      className={`${styles['header']} ${
        isSubMenuSidebar && isSidebar && styles['header--side-open']
      } ${props.header_transparent && styles['header--transparent']} ${
        !props.auth ? 'container' : ''
      } ${props.header_home && `${styles['header--home']} header--home`}
       `}
    >
      <div className={styles['header__content']}>
        <div className={styles['header__side-nav']}>
          <button
            type="button"
            className={styles['header__side-nav-icon']}
            onClick={() => {
              analyticsSideBarCustomEvent(analyticsEventParams);
              openSidebar(true);
            }}
          >
            {!isSidebar && <SideMenuIcon className="header__svg" />}
          </button>
          {!props.auth && (
            <button
              type="button"
              className={styles['header__side-nav-search']}
              onClick={() => {
                if (location.pathname === ROUTES.AvailableUnits.path) {
                  return;
                }
                setIsOpen(true);
              }}
            >
              <img
                src={searchIcon}
                alt=""
                role="presentation"
                className="header__svg"
              />
            </button>
          )}
        </div>
        <Link to={ROUTES.Home.path} className={styles['header__logo']}>
          {props.header_transparent ? (
            <img
              src={OrascomWhiteLogo}
              alt="logo"
              className="orascom-logo orascom-logo--white"
            />
          ) : (
            <img src={OrascomLogo} alt="logo" className="orascom-logo" />
          )}
        </Link>
        <div className={styles['header__nav']}>
          {process.env.NX_SHOW_LANGUAGES_OPTIONS ? <LanguageDropdown /> : null}

          {!props.auth && (
            <>
              <div className={styles['header__nav-currency-wrapper']}>
                <SelectDropdown
                  options={currencyOptions}
                  placeholder={selectedCurrency.label}
                  defaultValue={selectedCurrency}
                  onChange={(val) => {
                    if (val) {
                      setActiveCurrency(val.label);
                      setSelectedCurrency(val);
                    }
                  }}
                  isSearchable={false}
                  disabled={disableCurrencySetting.disabled}
                  selectedOption={selectedCurrency}
                />
                {disableCurrencySetting.info && (
                  <div className="tooltip__container">
                    <button onClick={() => setShowCurrencyTooltip(true)}>
                      <InfoIcon />
                    </button>
                    {showCurrencyTooltip && (
                      <Tooltip
                        position="bottom"
                        onClose={() => setShowCurrencyTooltip(false)}
                      >
                        <span>{disableCurrencySetting.info}</span>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
              <Link
                to={ROUTES.WishList.path}
                className={styles['header__nav-wishlist']}
                onClick={() =>
                  analyticsHeaderWishListClickEvent(analyticsEventParams)
                }
              >
                <img src={LoveIcon} alt="heart icon" />
                {t('wishList')}
              </Link>

              <div className={styles['header__nav-button']}>
                <NavyButton onClick={handleShowContactModal}>
                  {t('talkToSales')}
                </NavyButton>
              </div>
              <button
                type="button"
                className={styles['header__nav-search']}
                onClick={() => {
                  analyticsHeaderSearchClickEvent(analyticsEventParams);
                  handleShowSearchModal();
                }}
              >
                <img src={searchIcon} alt="search-icon" />
              </button>
            </>
          )}
        </div>
      </div>
      <div
        className={`${styles['header__search']} ${
          isOpen && styles['header__search--open']
        }`}
        ref={searchBarRef}
      >
        <SearchBar />
        {isOpen && (
          <button
            onClick={() => setIsOpen(false)}
            className={styles['header__close']}
          >
            <img src={closeIcon} alt="" role="presentation" />
          </button>
        )}
      </div>

      <Modal
        show={showContactModal}
        onClose={handleCloseModal}
        title={t('talkToSales')}
      >
        <ContactUsForm
          title={t('leaveYourContactInfo')}
          onCloseModal={handleCloseModal}
          talkToSalesForm
        />
      </Modal>
      <SideNavBar
        isSidebar={isSidebar}
        openSidebar={openSidebar}
        toggleSubMenuSideBarState={toggleSubMenuSideBarState}
        handleShowModal={handleShowContactModal}
      />

      <Modal
        show={showSearchModal}
        onClose={handleCloseModal}
        title={t('search')}
        bodyBackgroundClass="search-modal__background"
      >
        <div className="search-modal">
          <SearchBar />
        </div>
      </Modal>
    </header>
  );
}

export default Header;
