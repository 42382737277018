import styles from './destination-location.module.scss';
import { Location } from '@orascom/api-interfaces';

import { useTranslation } from 'react-i18next';
import { formatLocationInfoItem } from '../../utils/common.utils';

/* eslint-disable-next-line */
export interface DestinationLocationProps {
  locationTitle: string;
  locationDescription: string;
  locationImage: string;
  locations: Location[];
  masterPlanTitle: string;
  masterPlanSubTitle: string;
  masterPlanSubDescription: string;
  masterPlanImage: string;
  masterPlanBrochure: string;
  destinationSlug: string;
}

export function DestinationLocation(props: Readonly<DestinationLocationProps>) {
  const { t } = useTranslation();
  const {
    locationTitle,
    locationDescription,
    locationImage,
    locations,
    masterPlanTitle,
    masterPlanSubTitle,
    masterPlanSubDescription,
    masterPlanImage,
    masterPlanBrochure,
  } = props;
  return (
    <>
      <div className={`${styles['destination-location']} container`}>
        <div className={styles['destination-location__image']}>
          <img src={locationImage} alt="" loading="lazy" />
        </div>
        <div className={styles['destination-location__info']}>
          <h1
            className={`${styles['destination-location__info-title']} orascom__title`}
          >
            {t('howToFindUs')}
          </h1>
          <h2
            className={`${styles['destination-location__info-sub-title']} orascom__sub-title`}
          >
            {locationTitle}
          </h2>
          <p className={styles['destination-location__info-desc']}>
            {locationDescription}
          </p>
          <div className={styles['destination-location__image--responsive']}>
            <img src={locationImage} alt="" loading="lazy" />
          </div>
          <div className={styles['destination-location__info-details']}>
            {locations?.map((location) => {
              return (
                <div
                  className={styles['destination-location__info-details-item']}
                  key={location.id}
                >
                  <p className="orascom__sub-title">
                    {formatLocationInfoItem(location.time, location.unit)}
                  </p>
                  <p>{`${t('from')} ${location.name}`}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`${styles['destination-master-plan']} container`}>
        <div className={styles['destination-master-plan__info']}>
          <h1
            className={`${styles['destination-master-plan__info-title']} orascom__title`}
          >
            {masterPlanSubTitle}
          </h1>
          <h2
            className={`${styles['destination-master-plan__info-sub-title']} orascom__sub-title`}
          >
            {masterPlanTitle}
          </h2>
          <p className={styles['destination-master-plan__info-desc']}>
            {masterPlanSubDescription}
          </p>
          <div className={styles['destination-master-plan__image--responsive']}>
            <img src={masterPlanImage} alt="" loading="lazy" />
          </div>
          {props.destinationSlug === 'lustica-bay' ? (
            <a
              href="https://lustica-bay.sooada.com/masterplan/1026"
              rel="noreferrer"
              className={`btn btn--navy ${styles['destination-master-plan__info-button']}`}
            >
              {t('viewInteractiveMap')}
              <div className="white" />
            </a>
          ) : (
            <a
              href={masterPlanBrochure}
              download
              target="_blank"
              rel="noreferrer"
              className={`btn btn--navy ${styles['destination-master-plan__info-button']}`}
            >
              {t('downloadBrochure')}
              <div className="white" />
            </a>
          )}
        </div>
        <div className={styles['destination-master-plan__image']}>
          <img src={masterPlanImage} alt="" loading="lazy" />
        </div>
      </div>
    </>
  );
}

export default DestinationLocation;
