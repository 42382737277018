/* eslint-disable import/no-extraneous-dependencies */
import styles from './contact-us.module.scss';

import ContactUsImage from '../../assets/images/contact-us.webp';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import { useTranslation } from 'react-i18next';
import CountryOfficeCard, {
  CountryOffice as CountryOfficeInterface,
} from '../../components/common/country-office-card/country-office-card';
import { CardsSlider } from '@orascom/common-components';
import { useEffect, useState } from 'react';
import { Contact as ContactUtilities } from '../../utils/contact.utils';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const contactDocTitle = getDocTitle('Contact Us');
export function ContactUs() {
  const { t } = useTranslation();
  const [offices, setOffices] = useState<CountryOfficeInterface[]>([]);

  useEffect(() => {
    ContactUtilities.getSalesContacts()
      .then((res) => setOffices(res))
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Helmet>
        <title>{contactDocTitle}</title>
        <meta property="og:title" content={contactDocTitle} />
        <meta name="description" content={t('contactUsDisclaimer')} />
        <meta property="og:description" content={t('contactUsDisclaimer')} />
      </Helmet>
      <div className={`container ${styles['container']}`}>
        <img
          className={styles['image']}
          src={ContactUsImage}
          alt="contact-us"
          loading="lazy"
        />
        <h1 className="orascom__title">{t('contactUs')}</h1>
        <h2 className="orascom__sub-title">{t('sendUsAMessage')}</h2>
        <div className={styles['wrapper']}>
          <ContactUsForm contactUsPage />
        </div>

        {/* {offices.length > 0 && (
          <div className={styles['offices']}>
            <p className="orascom__title">{t('contactUs')}</p>
            <p className="orascom__sub-title">{t('whereYouCouldFindUs')}</p>
            <CardsSlider>
              {offices.map((office) => (
                <CountryOfficeCard key={uuidv4()} office={office} />
              ))}
            </CardsSlider>
          </div>
        )} */}
      </div>
    </>
  );
}

export default ContactUs;
